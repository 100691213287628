const ClientsData = [
    {
        id: 1,
        profile: require("../../Assets/Doctors/doctor1.jpg"),
        name: "Steven Johy",
        position: "CEO of Company",
        review: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, velit iusto libero consequatur dolorum doloremque voluptas voluptates atque repudiandae iste?",
    },
    {
        id: 2,
        profile: require("../../Assets/Doctors/doctor1.jpg"),
        name: "Omit Jacson",
        position: "Supervisor",
        review: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, velit iusto libero consequatur dolorum doloremque voluptas voluptates atque repudiandae iste?",
    },
    {
        id: 3,
        profile: require("../../Assets/Doctors/doctor1.jpg"),
        name: "Johnson",
        position: "Team Leader",
        review: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, velit iusto libero consequatur dolorum doloremque voluptas voluptates atque repudiandae iste?",
    },
]

export default ClientsData;