const DoctorsData = [
    {
        id: 1,
        profile: require("../../Assets/Doctors/doctor1.jpg"),
        name: "Dr. Jemse Watson",
        spec: "Necrologist",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, doloremque."
    },
    {
        id: 2,
        profile: require("../../Assets/Doctors/doctor1.jpg"),
        name: "Dr. Jemse Watson 1",
        spec: "Necrologist",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, doloremque."
    },
    {
        id: 3,
        profile: require("../../Assets/Doctors/doctor1.jpg"),
        name: "Dr. Jemse Watson 2",
        spec: "Necrologist",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, doloremque."
    },
    {
        id: 4,
        profile: require("../../Assets/Doctors/doctor1.jpg"),
        name: "Dr. Jemse Watson 3",
        spec: "Necrologist",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, doloremque."
    },
    {
        id: 5,
        profile: require("../../Assets/Doctors/doctor1.jpg"),
        name: "Dr. Jemse Watson 4",
        spec: "Necrologist",
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, doloremque."
    },
]
export default DoctorsData;